// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

// Components
import Chevron from "./svg/chevron";

interface ArrowProps {
  carouselPositioning?: boolean;
  small?: boolean;
  orange?: Boolean;
  next?: Boolean;
  onClick?: any;
}

const arrow: FC<ArrowProps> = ({
  carouselPositioning,
  small,
  orange,
  next,
  onClick,
}) => {
  return (
    <StyledArrow
      carouselPositioning={carouselPositioning}
      small={small}
      orange={orange}
      right={next}
      onClick={onClick}
    >
      <Chevron right={next} black={orange} />
    </StyledArrow>
  );
};

export default arrow;

const StyledArrow = styled.div<{
  carouselPositioning: boolean;
  small: boolean;
  orange: Boolean;
  right: Boolean;
}>`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: ${(props) =>
    props.orange ? props.theme.colors.primary : null};
  position: absolute;
  top: ${(props) => (props.carouselPositioning ? "176px" : "50%")};
  left: ${(props) =>
    props.orange ? (props.right ? "87%" : "13%") : props.right ? "100%" : "0%"};
  z-index: 10;
  cursor: pointer;
  transform: translate(-50%, -50%);

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: flex;
    height: ${(props) => (props.small ? 30 : 60)}px;
    width: ${(props) => (props.small ? 28 : 55)}px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    height: ${(props) => (props.small ? 45 : 90)}px;
    width: ${(props) => (props.small ? 42 : 83)}px;
    top: ${(props) => (props.carouselPositioning ? "220px" : "50%")};
    left: ${(props) =>
      props.orange
        ? props.right
          ? "87%"
          : "13%"
        : props.right
        ? "100%"
        : "0%"};
  }

  @media (min-width: 1620px) {
    left: ${(props) =>
      props.orange
        ? props.right
          ? "80%"
          : "20%"
        : props.right
        ? "100%"
        : "0%"};
  }
`;
