// Dependencies
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GraphImg from "graphcms-image";

// Components
import Arrow from "../components/arrow";
import Target from "./target";

interface Props {
  weaponsArray?: Array<any>;
}

const WeaponCarousel: React.FC<Props> = ({ weaponsArray }) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        weapons(stage: PUBLISHED) {
          slug
          name
          weaponId
          image {
            handle
            width
            height
          }
        }
      }
    }
  `);
  let weapons;
  weaponsArray ? (weapons = weaponsArray) : (weapons = data.cms.weapons);

  const carousel = useRef();
  const [carouselRef, setCarouselRef] = useState(null);

  useEffect(() => {
    setCarouselRef(carousel);
  }, []);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "0",
    slidesToScroll: 1,
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: "576px",
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      {weapons.length > 3 ? (
        <StyledSlider ref={carousel} {...settings} className="weaponCarousel">
          {weapons.map((weapon) => {
            return (
              <Link key={weapon.weaponId} to={`/arsenal/${weapon.slug}`}>
                <Slide>
                  <Target />
                  <StyledImg
                    image={{
                      handle: weapon.image.handle,
                      width: 300,
                      height: 300 / (weapon.image.width / weapon.image.height),
                    }}
                    outerWrapperClassName="GraphCmsImgWrapper"
                    blurryPlaceholder={false}
                    withWebp
                  />
                </Slide>
                <StyledParagraph>{weapon.name}</StyledParagraph>
              </Link>
            );
          })}
        </StyledSlider>
      ) : (
        <SlideContainer>
          {weapons.map((weapon) => {
            return (
              <StyledLink key={weapon.weaponId} to={`/arsenal/${weapon.slug}`}>
                <Slide>
                  <Target />
                  <StyledImg
                    image={weapon.image}
                    outerWrapperClassName="GraphCmsImgWrapper"
                    blurryPlaceholder={false}
                  />
                </Slide>
                <StyledParagraph>{weapon.name}</StyledParagraph>
              </StyledLink>
            );
          })}
        </SlideContainer>
      )}
      <LeftButton onClick={() => carouselRef.current.slickPrev()} />
      <RightButton onClick={() => carouselRef.current.slickNext()} />
    </CarouselContainer>
  );
};
export default WeaponCarousel;

const LeftButton = styled.div`
  width: 20%;
  height: 200px;
  position: absolute;
  left: 7%;
  bottom: 80px;
  display: none;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: block;
  }
`;
const RightButton = styled.div`
  width: 20%;
  height: 200px;
  position: absolute;
  right: 7%;
  bottom: 80px;
  display: none;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: block;
  }
`;
const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const StyledImg = styled(GraphImg)`
  width: 100%;
  max-width: 300px;
`;

const Slide = styled.div`
  height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  flex: 1;

  .GraphCmsImgWrapper {
    flex: 1;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
  }
`;

const StyledSlider = styled(Slider)`
  height: 100%;
`;

const StyledParagraph = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledLink = styled(Link)`
  flex: 1;
`;
