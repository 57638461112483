// Dependencies
import React, { Fragment } from "react";
import styled from "styled-components";
import Fade from "react-reveal";

// Components
import SEO from "../components/seo";
import { Container } from "../pages/index";
import List from "../components/list";
import CourseSection from "../components/courseSection";
import PackageSection from "../components/packageSection";
import WeaponCarousel from "../components/weaponCarousel";
import { renderTile } from "../components/weaponSection";
import { ListTitle } from "../components/list";
import { Text } from "../components/description";
import {
  NavContainer,
  StyledLink,
  StyledValue,
} from "../pages/arsenal/weapons_list";

interface Props {
  pageContext: {
    title: string;
    text: string[];
    price: number;
    htmlDescription?: string;
    descriptionList: string[];
    recommendedCourses?: number[];
    recommendedPackages?: number[];
    id: number;
    slug: string;
    descriptionListHeader: string;
    image: any;
    isPackage: Boolean;
    includedGuns?: number[];
    objectArray: object[];
    weapons: object[];
  };
}

const Course: React.FC<Props> = (props) => {
  const course = props.pageContext;
  const data = course.objectArray;
  const recommended = course.isPackage
    ? data.filter(
        (e) =>
          e.packageId === course.recommendedPackages[0] ||
          e.packageId === course.recommendedPackages[1] ||
          e.packageId === course.recommendedPackages[2]
      )
    : data.filter(
        (e) =>
          e.courseId === course.recommendedCourses[0] ||
          e.courseId === course.recommendedCourses[1] ||
          e.courseId === course.recommendedCourses[2]
      );

  const weapons = course.weapons;

  const filteredWeapons =
    course.isPackage && course.includedGuns.length > 0
      ? weapons.filter((e) => course.includedGuns.includes(e.weaponId))
      : [];

  return (
    <Fragment>
      <Fade>
        <CourseWrapper>
          <Container>
            <NavContainer>
              <StyledLink to={course.isPackage ? "/pakiety/" : "/szkolenia/"}>
                {course.isPackage
                  ? `Pakiety Rekreacyjne  \u2192`
                  : `Szkolenia \u2192`}
              </StyledLink>
              <StyledValue>{course.title}</StyledValue>
            </NavContainer>
            <CourseSection
              title={course.title}
              pictureLeft={true}
              text={course.isPackage ? course.descriptionList : course.text}
              linkTo={`${course.slug}`}
              image={course.image}
              price={course.price}
              buyButton={true}
              isPackage={course.isPackage}
            />
            {!course.isPackage && (
              <DescriptionDivWithHtml
                dangerouslySetInnerHTML={{ __html: course.htmlDescription }}
              ></DescriptionDivWithHtml>
            )}
            {course.isPackage ? (
              <TextSection>
                <StyledListTitle>
                  {course.descriptionListHeader}
                </StyledListTitle>
                {course.text.map((content, index) => {
                  return <Text key={index}>{content}</Text>;
                })}
              </TextSection>
            ) : (
              <List
                header={course.descriptionListHeader}
                details={course.descriptionList}
              ></List>
            )}
            {course.isPackage && filteredWeapons.length > 3 ? (
              <>
                <ListTitle>Broń w pakiecie</ListTitle>
                <WeaponCarousel weaponsArray={filteredWeapons} />
              </>
            ) : course.isPackage && filteredWeapons.length > 0 ? (
              <>
                <ListTitle>Broń w pakiecie</ListTitle>
                <WeaponListContainer>
                  {filteredWeapons.map((weapon, index) => {
                    return (
                      <TileContainer>{renderTile(weapon, index)}</TileContainer>
                    );
                  })}
                </WeaponListContainer>
              </>
            ) : null}
            {recommended instanceof Array && recommended.length > 0 ? (
              <>
                <Lead>{"Zobacz również"}</Lead>
                <PackageSection
                  slug={course.isPackage ? "/pakiety/" : "/szkolenia/"}
                  course={course.isPackage ? false : true}
                  packages={recommended}
                ></PackageSection>
              </>
            ) : null}
          </Container>
        </CourseWrapper>
      </Fade>
      <SEO />
    </Fragment>
  );
};

export default Course;

const Lead = styled.h3`
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  border-bottom: 4px solid ${(props) => props.theme.colors.primary};
  margin-bottom: ${(props) => props.theme.space[4]}px;
  padding-bottom: ${(props) => props.theme.space[1]}px;
`;

const WeaponListContainer = styled.div`
  margin-top: ${(props) => props.theme.space[7]}px;
  margin-bottom: ${(props) => props.theme.space[7]}px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-right: -${(props) => props.theme.space[7]}px;
    margin-left: -${(props) => props.theme.space[7]}px;
  }
`;

const TileContainer = styled.div`
  margin: 0 ${(props) => props.theme.space[7]}px;
  flex: 1;
  max-width: 300px;
`;

const DescriptionDivWithHtml = styled.div`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.1rem;
  margin-bottom: ${(props) => props.theme.space[3]}px;

  p {
    margin: ${(props) => props.theme.space[5]}px 0;
  }

  ul li {
    list-style-type: none !important;
    padding-left: ${(props) => props.theme.space[3]}px;
    font-size: ${(props) => props.theme.fontSizes[2]}px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.white};
    letter-spacing: 0.1rem;
    position: relative;
    margin-top: ${(props) => props.theme.space[2]}px;
    margin-left: 30px;
    ::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary};
      position: absolute;
      left: 0;
      top: 5px;
    }
  }

  ol li {
    margin-left: 17px;
    font-size: ${(props) => props.theme.fontSizes[2]}px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.white};
    letter-spacing: 0.1rem;
    margin-top: ${(props) => props.theme.space[2]}px;
  }
`;

const StyledListTitle = styled(ListTitle)`
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const TextSection = styled.div`
  margin-bottom: ${(props) => props.theme.space[6]}px;
`;

const CourseWrapper = styled.div`
  margin: 0 12px;
`;
